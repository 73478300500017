import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-giving',
  templateUrl: './giving.component.html',
  styleUrls: ['./giving.component.scss']
})
export class GivingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  bgSrc: string = null;
  images = ['slideshow01.png', 'slideshow02.png'];

  quote = { verse: '', scripture: '' };
  quotes = [
    {
      verse: 'Acts 2:4',
      scripture: 'And they were all filled with the Holy Ghost, and began to speak with other tongues, as the Spirit gave them utterance.'
    },
    {
      verse: 'Acts 2:38',
      // tslint:disable-next-line:max-line-length
      scripture: 'Then Peter said unto them, Repent, and be baptized every one of you in the name of Jesus Christ for the remission of sins, and ye shall receive the gift of the Holy Ghost.'
    }
  ];

  carouselIndex = 0;
  showCarousel = true;
  typewriter;
  carouselAutoAdvanceTimer;

  upcomingEvents = null;
  EVENTS_URL = 'https://clients6.google.com/calendar/v3/calendars/en.usa%23holiday@group.v.calendar.google.com/events?';

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.updateCarousel();
    this.getEvents();
  }

  updateCarousel(): void {
    if (this.carouselIndex >= this.images.length) {
      this.carouselIndex = 0;
    }

    if (this.carouselIndex < 0) {
      this.carouselIndex = this.images.length - 1;
    }

    this.bgSrc = this.images[this.carouselIndex];
    this.quote.verse = this.quotes[this.carouselIndex].verse;
    this.quote.scripture = '';

    // Scripture Animation
    let i = 0;
    const scripture = this.quotes[this.carouselIndex].scripture;
    const len = this.quotes[this.carouselIndex].scripture.length - 1;

    if (this.typewriter) {
      clearInterval(this.typewriter);
    }

    this.typewriter = setInterval(() => {
      this.quote.scripture += scripture[i];
      i++;
      if (i > len) {
        clearInterval(this.typewriter);
        this.startTimer();
      }
    }, 30);

    // Background Aniamtion
    setTimeout(() => {
      this.showCarousel = true;
    }, 0);
  }

  nextCarousel(direction: number): void {
    if (this.carouselAutoAdvanceTimer) {
      clearTimeout(this.carouselAutoAdvanceTimer);
    }

    this.showCarousel = false;
    this.carouselIndex += direction;
    this.updateCarousel();
  }

  startTimer(): void {
    this.carouselAutoAdvanceTimer = setTimeout(() => {
      this.nextCarousel(1);
    }, 3000);
  }

  getEvents(): void {
    const params: HttpParams = new HttpParams()
      .set('calendarId', 'veeol6mv603q845f1m2rj4vqlo@group.calendar.google.com')
      .set('singleEvents', 'true')
      .set('maxResults', '4')
      .set('sanitizeHtml', 'true')
      .set('timeZone', 'America/New_York')
      .set('timeMin', (new Date()).toISOString())
      .set('key', 'AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs');

    this.http.get(this.EVENTS_URL, { params })
      .subscribe(response => {
        this.upcomingEvents = response;
      });
  }
}
